/*
 * Shortcode: Teams.less
 * -----------------------------------------------
*/
.team-block .team-upper-block {
  overflow: hidden;
  position: relative;
}
.team-block .team-upper-block .styled-icons {
  background: #fff none repeat scroll 0 0;
  position: absolute;
  text-align: center;
  transform: translateY(-5%);
  transition: all 300ms linear 0s;
  width: 100%;
}
.team-block:hover .team-upper-block .styled-icons {
  transform: translateY(-100%);
}
.team-block:hover .team-upper-block img {
  transform: translateY(-35px) scale(1.04);
}
.team-block .team-upper-block img {
  transition: all 300ms linear 0s;
}
.team-block {
  margin: 0 auto 30px;
  .team-thumb {
    overflow: hidden;
    position: relative;
    .team-overlay {
      height: 85.4%;
      padding: 40px 20px 0 30px;
      position: absolute;
      top: -90%;
      .transition(all 300ms ease-in-out 0s);
      width: 100%;
      .styled-icons {
        position: absolute;
        bottom: 4px;
         a {
          border-color: @white-base;
          &:hover {
            background: @white-base none repeat scroll 0 0;
          }
          i {
            color: @white-base;
          }
        }
      }
    }
    .styled-icons {
      bottom: 0;
      padding: 7px 20px 0 0;
      position: absolute;
      right: 0;
      width: 84%;
      a {
        margin: 0px;
        i {
          color: @white-base;
          font-size: 14px;
          margin-top: 8px;
        }
        &:hover {
          border-color: @white-base;
          .transition(all 100ms ease-in-out 0s);
        }
      }
      &::after {
        -moz-border-bottom-colors: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        border-image: none;
        border-style: solid;
        border-width: 22px;
        bottom: 0;
        content: "";
        display: block;
        height: 40px;
        left: -44px;
        position: absolute;
        width: 40px;
      }
    }
  }
  .team-bottom-part {
    background: @gray-lighter none repeat scroll 0 0;
    margin-top: 6px;
    padding: 10px 16px 12px 30px;
  }
}
.team-block:hover .team-overlay {
  top: 0;
}
.team-block {
  margin: 0 auto 30px;
  .team-thumb {
    overflow: hidden;
    position: relative;
    .team-overlay {
      height: 85.4%;
      padding: 40px 20px 0 30px;
      position: absolute;
      top: -90%;
      .transition(all 300ms ease-in-out 0s);
      width: 100%;
      .styled-icons {
        position: absolute;
        bottom: 4px;
         a {
          border-color: @white-base;
          &:hover {
            background: @white-base none repeat scroll 0 0;
          }
          i {
            color: @white-base;
          }
        }
      }
      &::after {
        -moz-border-bottom-colors: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        border-image: none;
        border-style: solid;
        border-width: 22px;
        bottom: -44px;
        content: "";
        display: block;
        height: 44px;
        left: -2px;
        position: absolute;
        width: 40px;
      }
    }
    .styled-icons {
      bottom: 0;
      padding: 7px 20px 0 0;
      position: absolute;
      right: 0;
      width: 84%;
      a {
        margin: 0px;
        i {
          color: @white-base;
          font-size: 14px;
          margin-top: 8px;
        }
        &:hover {
          border-color: @white-base;
          .transition(all 100ms ease-in-out 0s);
        }
      }
      &::after {
        -moz-border-bottom-colors: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        border-image: none;
        border-style: solid;
        border-width: 22px;
        bottom: 0;
        content: "";
        display: block;
        height: 40px;
        left: -44px;
        position: absolute;
        width: 40px;
      }
    }
  }
  .team-bottom-part {
    background: @gray-lighter none repeat scroll 0 0;
    margin-top: 6px;
    padding: 10px 16px 12px 30px;
  }
}
.team-block:hover .team-overlay {
  top: 0;
}