@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        Carfixing
Version:        1.0
Primary use:    CarFixing | Car Repair & Car Wash HTML Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/
 
// Table of contents
@import "less-repairshop/table-of-content.less";


// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";


//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/flaticon-set-carfixing.css";
@import "../css/utility-classes.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800|family=Raleway:400,200,300,500,600,700,800,900|family=Titillium+Web:400,200,300,600,700,900);

 
// Initialize Variables
@import "less-repairshop/variables.less";
@import "less-repairshop/mixins.less";
// Typography
@import "less-repairshop/typography.less";

// Common Styles
@import "less-repairshop/common.less";
@import "less-repairshop/extra.less";
@import "less-repairshop/overlay.less";

// Header
@import "less-repairshop/header.less";

// Nav
@import "less-repairshop/nav.less";

// Content Blocks
@import "less-repairshop/topbar.less";
@import "less-repairshop/inner-header-title.less";
@import "less-repairshop/vertical-nav.less";
@import "less-repairshop/menu-full-page.less";
@import "less-repairshop/boxed-layout.less";
@import "less-repairshop/form.less";
@import "less-repairshop/side-push-panel.less";
@import "less-repairshop/box-hover-effect.less";
@import "less-repairshop/work-gallery.less";
@import "less-repairshop/gallery-isotope.less";
@import "less-repairshop/sliders.less";
@import "less-repairshop/home.less";
@import "less-repairshop/about.less";
@import "less-repairshop/contact.less";
@import "less-repairshop/services.less";
@import "less-repairshop/shop.less";
@import "less-repairshop/blog.less";
@import "less-repairshop/our-services-gallery.less";
@import "less-repairshop/causes.less";

// Shortcodes
@import "less-repairshop/shortcodes.less";


// Widgets
@import "less-repairshop/widgets.less";


// Footer
@import "less-repairshop/footer.less";