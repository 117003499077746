/*
 * our-services-gallery
 * -----------------------------------------------
*/

.our-services-gallery {
	.gallery-item {
		position: relative;
		overflow: hidden;
		.thumb {
			position: relative;
			overflow: hidden;
		}

		.overlay-shade {
			position: absolute;
			display: inline-block;
			vertical-align: middle;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 12;
			background-color: #333;
			opacity: 0;
			filter: alpha(opacity=0);
			.transition(opacity .3s ease-in-out);
		}

		&:hover .overlay-shade {
			opacity: 0.9;
			filter: alpha(opacity=90);
		}

		.icons-holder {
			position: absolute;
			top: 0%;
			left: 50%;
			display: inline-block;
			width: auto;
			height: 36px;
			vertical-align: middle;
			z-index: 13;
			.transition(all .3s ease-in-out);

			.icons-holder-inner {
				position: relative;
				display: inline-block;
				vertical-align: middle;
				margin: -18px 0 0 -50%;

				a {
					opacity: 0;
					filter: alpha(opacity=0);
					.transition(all .3s ease-in-out);
				}
			}
		}

		&:hover .icons-holder {
			top: 50%;
			.icons-holder-inner {
				a {
					opacity: 1;
					filter: alpha(opacity=1);
				}
			}
		}

		.our-services-details {
			position:inherit;
		 	padding: 10px 15px;
		 	background-color: @white-f8;
             .discount {
				 position:absolute;
				 display:inline-block;
				 z-index: 15;
				 right: 10px;
				 top: -40px;
				 }
		 	.title {
			 	margin: 0;
			 	a {
					color: @headings-color;
				}
			}
		}

		.text-holder {
		 	box-sizing: border-box;
			display: block;
			height: 100%;
			left: 0;
			opacity: 0;
			overflow: hidden;
			padding: 25px 30px 60px;
			position: absolute;
			top: 0;
			.transition(opacity 0.3s ease-in-out 0s);
			width: 100%;
			z-index: 13;

		 	.title {
		 		color: #fff;
			}
		}

		&:hover .text-holder {
			opacity: 1;
		}
	}
}
