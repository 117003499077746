/*
 * Shortcode: Pricing.less
 * -----------------------------------------------
*/
.pricing-table {
	margin-bottom: 30px;
	position: relative;
	
	.table-price {
		color: @black-111;
		font-size: 45px;

		span {
			font-size: 13px;
			vertical-align: middle;
		}
	}

	&.featured {
		border: 1px solid @black-111;
	}

	.table-type {
		display: inline;
	}

	.btn-signup {
		margin-right: 0;
		width: 100%;
	}

	.table-list {
		list-style: outside none none;
		padding: 10px 0 10px 20px;

		li {
			border-bottom: 0;
			font-size: 12px;
			padding: 10px 0;

			i {
			  	margin-right: 8px;
			}
		}
	}

	.package-type {
		span {
			position: relative;
			padding-left: 15px;
			margin-left: 10px;
			
			&::after {
				background: #2d915b;
				content: "";
				height: 20px;
				left: -20px;
				position: absolute;
				top: 11px;
				width: 20px;
			}
		}
	}
}
.pricing {
	background-color: #3FB179;
	padding: 80px 60px;
	position: relative;
	-webkit-transition: 0.3s all ease-in-out 0s;
	-o-transition: 0.3s all ease-in-out 0s;
	transition: 0.3s all ease-in-out 0s;
}
.pricing:hover {
	background-color: #121b26 !important;
}
.pricing .table-price {
	font-size: 4rem !important; /* =64px */
	line-height: 1.2;
	font-family: "Open Sans", sans-serif;
	margin: 0 0 20px;
	font-weight: 200;
}
.pricing .table-title {
	color: #fff;
	margin: 0;
	padding: 15px 0;
	padding-top: 0;
	text-transform: uppercase;
}
.pricing.table-horizontal {
	background-color: #3FB179;
	border-radius: 13px;
	padding: 60px 40px;
}








