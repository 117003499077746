/*
 * Shortcode: Toggles-Accordions.less
 * -----------------------------------------------
*/
.panel-group {
	.panel {
		box-shadow: none;
		-webkit-border-radius: 0 0 0 0;
		-moz-border-radius: 0;
		-ms-border-radius: 0 0 0 0;
		border-radius: 0 0 0 0;
		border: none;
		position: relative;
	}
	.panel-title {
		font-size: 13px;
		a {
			background: @white-f8;
		    display: block;
		    font-size: 14px;
		    font-weight: 500;
		    padding: 10px 15px 10px 48px;
		    white-space: normal;
			&.active {
				background: #16174f none repeat scroll 0 0 !important;
				border-bottom: 1px solid #d3d3d3 !important;
				color: #fff;
				.open-sub:after {
					display: none;
				}

				&::after {
					color: @white-base;
					content: "\f068";
				}
			}		

			&::after {
			    color: #888;
				content: "\f067";
			    font-family: fontawesome;
			    font-size: 14px;
			    left: 0;
			    padding: 10px 14px;
			    position: absolute;
			    top: 0;
			}
		}

		&.active&:hover,
		&.active&:focus {
			a {
				color: @black-333;
			}
		}
		.open-sub {
			display: block;
			position: relative;
			&:before, 
			&:after {
				content: "";
				display: block;
				position: absolute;
				height: 1px;
				top: 50%;
				margin-top: -.5px;
				left: 50%;
			}
		}
	}
	.panel-content {
		background: @white-base none repeat scroll 0 0;
  		border: 1px solid #ddd;
		padding: 10px 20px 20px;
		> :last-child {
			margin-bottom: 0;
		}
	}
}

.panel-group.white .panel-title a {
  	background-color: @white-base;
}

/* -------- Toggle ---------- */
.panel-group {
	&.toggle {
		.panel-heading {
			padding: 0;
			border-bottom: 0;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
		.panel-body {
			padding: 10px 0 5px 25px;
		}
	}
	&.accordion,
	&.toggle {
		&.transparent {
			.panel-title {
				a, a.active {
					background: transparent !important;
					border: none !important;
					color: @black-333;
					&::after {
						color: @black-333;
						background: transparent !important;
					}
				}
			}
			.panel-content {
				background: transparent !important;
			}
		}
	}
}
.panel-group.toggle .panel-heading + .panel-collapse > .panel-body, 
.panel-group.toggle .panel-heading + .panel-collapse > .list-group {
	border-top: none;
}